import { Component, Vue } from 'vue-property-decorator'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import ListPanelIcon from '@/components/listPanelIcon/ListPanelIcon.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'
import ListGroupPermission from '@/components/ListGroupPermission/ListGroupPermission.vue';

//services
import { Alert } from '@/services/notification'

//Vuex
const namespace = 'group';
import { Action, Getter } from 'vuex-class';

//models
import { User } from '@/molds/User';
import { Group } from '@/store/modules/group/group';
import { FieldSearchModel } from '@/molds/FieldSearchModel';
import { ListPanelIconsModel } from '@/molds/ListPanelIconsModel'

//wrappers
import {
  Container,
  Box,
  wIcon,
  WColumns,
  WColumn,
  WButton,
  WHr,
  WSubtitle,
  Arrow,
  Loader,
} from '@/components/wrappers';

@Component({
  components: {
    PrivateContent,
    WColumns,
    WColumn,
    Container,
    Box,
    ListPanelIcon,
    WButton,
    WHr,
    wIcon,
    FieldSearch,
    WSubtitle,
    Arrow,
    Loader,
    ListGroupPermission
  }
})
export default class MyGroup extends Vue {

	@Action('fetchGroup', { namespace }) fetchGroup: any;
	@Action('fetchUsersGroup', { namespace }) fetchUsersGroup: any;
	@Action('dispatchInviteGroup', { namespace }) dispatchInviteGroup: any;
	@Getter('group', { namespace }) group!: Group;
	@Getter('usersGroup', { namespace }) usersGroup: any;
	@Getter('loader', { namespace }) isLoading: any;
	@Getter('error', { namespace }) error: any;

	alert = new Alert(this.$store);
	idGroup = ""
	membersActive = 0;
  requestedInvitation = 0;
  membersPending = 0;

	mounted(){
		document.title = "Grupo"
		this.initPage()
	}

  countUsersMembers() {
    this.membersPending = 0; //-- Reload (set to zero) with the page
    this.requestedInvitation = 0; //-- Reload (set to zero) with the page
    this.membersPending = 0; //-- Reload (set to zero) with the page
    for (let i = 0; i < this.usersGroup.length; i++)
    {
      if(this.usersGroup[i].status == "3")
        this.membersActive++;
      else if(this.usersGroup[i].status == "2")
        this.requestedInvitation++;
      else if(this.usersGroup[i].status == "1")
        this.membersPending++;
    }
  }

	initPage() {
		if(!isNaN(parseInt(this.$route.params.id))){
			this.idGroup = this.$route.params.id
			this.getGroup()
		}else{
			this.$router.replace("/404")
		}
	}

	async getGroup() {
		await this.fetchGroup(this.idGroup)
		if(this.error){
			this.alert.apiMessage("Não foi possível carregar o grupo requerido.", this.error, "warning")
			this.$router.replace("/404")
		}else{
			this.setTitle()
			this.getUsersGroup();
		}
	}

	async getUsersGroup() {
		await this.fetchUsersGroup(this.idGroup)
		if(this.error){
			console.log(this.error);
			
			this.alert.apiMessage("Não foi possível carregar os membros do grupo.", this.error, "danger")
		}else{
			this.countUsersMembers();
		}
	}	

	setTitle(): void {
		document.title = this.group.shortName
	}

	listPanelIcons: ListPanelIconsModel = {
    fieldName: 'name'
  }

  fieldSearchConfig: FieldSearchModel = {
    isOnlyButton: true,
    isOnlyButtonType: 'is-primary',
    label: 'Convidar',
    modalTitle: 'Buscar usuário',
    showColum: 'name',
    endpoint: "/user",
    // url: 'https://jsonplaceholder.typicode.com/users',
    urlQueryKey: '_limit=10&name'
  }

  get listUsers(): User[] {
    return this.usersGroup;
  }

  selectedFieldSearch(user: User): void {
    this.openDialogConfirm(`Enviar convite para ${user.name} fazer parte do grupo ${this.group.name}?`, () => {
      this.dispatchInvite(user)
    })
  }

  async dispatchInvite(user: User): Promise<void> {
    const data = {
      idGroup: this.group.idGroup,
      user: {
        idUser: user.idUser
      }
    }

    await this.dispatchInviteGroup(data)

    if (this.error) {
      this.alert.apiMessage("Não foi possível enviar convite.", this.error, "warning")
    } else {
      this.alert.success("Convite enviado.")
      this.getGroup()
    }
  }

  goToGroup(): void {
    this.$router.push({ path: `/group/${this.group.idGroup}` })
  }

  clickBtnEditUser(user: User): void {
    this.openModal(user)
  }

  clickBtnDeleteUser(user: User): void {
    this.openDialogConfirm(`Deseja remover ${user.name} do grupo ${this.group.name}?`, () => {
      console.log('Deletado');
    })
  }

  clickBtnExitGroup(): void {
    this.openDialogConfirm(`Deseja sair do grupo ${this.group.name}?`, () => {
      console.log('Sair do grupo');
    });
  }

  openDialogConfirm(message: string, executeConfirm: Function): void {
    this.$buefy.dialog.confirm({
      message: message,
      onConfirm: () => {
        executeConfirm()
      }
    })
  }
	//TODO deprecated
  openModal(user: User): void {
    this.$buefy.modal.open({
      parent: this,
      component: ListGroupPermission,
      customClass: '',
      width: '470',
      props: {
        user: user,
        group: this.group
      },
			events: {
				close: () => {
					this.setTitle()
				}
			}
    })
  }
}
