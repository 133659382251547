import { Component, Prop, Vue } from 'vue-property-decorator'

//wrappers
import { Modal, WColumns, WColumn, Loader, WButton } from '@/components/wrappers'

//molds
import { User } from '@/molds/User';
import { Group } from '@/store/modules/group/group';
import { GroupPermission } from '@/molds/GroupPermission';

//services
import { Alert } from '@/services/notification';

//Vuex
const namespace = 'permission';
import { Action, Getter } from 'vuex-class';

@Component({
	components: {
		Modal,
		WColumn,
		WColumns,
		Loader,
		WButton
	}
})
export default class ListGroupPermission extends Vue {
	@Prop() user!: User
	@Prop() group!: Group

	@Action('fetchGroupUserPermission', { namespace }) fetchGroupUserPermission: any;
	@Action('updateGroupUserPermission', { namespace }) updateGroupUserPermission: any;
	@Getter('groupUserPermission', { namespace }) groupUserPermission: any;
	@Getter('error', { namespace }) error: any;

	alert = new Alert(this.$store)
	isLoading = true;

	sendPermissons: string[] = []

	mounted(): void {
		document.title = "Permissões de usuário"
		this.fetchPermission()
	}

	async fetchPermission(): Promise<void> {
		const data = {
			idGroup: this.group.idGroup, idUser: this.user.idUser
		}

		await this.fetchGroupUserPermission(data)

		if (this.error) {
			this.alert.apiMessage("Não foi possível carregar permissões de usuário.", this.error, "danger")
		} else {
			this.mountPermissisons()
		}
		this.isLoading = false
	}

	async dispatchPermission(): Promise<void> {
		this.isLoading = true
		
		await this.updateGroupUserPermission(this.mountPermToSend())
		
		if(this.error){
			this.alert.apiMessage("Não foi possível salvar permissão.", this.error, "danger")
		}else{
			this.isLoading = false
			this.alert.success("Sucesso!")
		}
	}

	mountPermToSend(): any {
		let permission = "{"

		this.mapPermissions.forEach((perm: GroupPermission) => {
			if(perm.permission){
				permission += `"${perm.fieldName}":${perm.permission},`
			}
		});
		permission = permission.replace(/,\s*$/, "");
		permission += "}"

		return {
			idGroup: this.group.idGroup, 
			idUser: this.user.idUser,
			permission: JSON.parse(permission)
		}
	}

	mountPermissisons(): void {
		Object.keys(this.groupUserPermission).map((key: string) => 
			this.setPerm(key, this.groupUserPermission[key])
		)	
	}

	setPerm(key: string, perm: boolean){
		for (let i = 0; i < this.mapPermissions.length; i++) {
			if(this.mapPermissions[i].fieldName == key){
				this.mapPermissions[i].permission = perm
			}
		}
	}

	mapPermissions: GroupPermission[] = [
		// {
		// 	fieldName: "accessGroup",
		// 	label: "Detalhes",
		// 	description: "Visualiza detalhes do grupo incluindo pessoas do grupo",
		// 	type:"group",
		// 	permission: false
		// },
		// {
		// 	fieldName: "editGroup",
		// 	label: "Editar",
		// 	description: "Permite editar nome e abreviação do grupo",
		// 	type:"group",
		// 	permission: false
		// },
		{
			fieldName: "editPermissionUsersGroup",
			label: "Editar permissões",
			description: "Edita permissões usuários no grupo",
			type:"user",
			permission: false
		},
		{
			fieldName: "removeUsersGroup",
			label: "Remover",
			description: "Remover usuários do grupo",
			type:"user",
			permission: false
		},
		// {
		// 	fieldName: "exitGroup",
		// 	label: "Sair",
		// 	description: "Sair do grupo",
		// 	type:"group",
		// 	permission: false
		// },
		// {
		// 	fieldName: "requestEntry",
		// 	label: "Pedir para entrar",
		// 	description: "Solicitar entrada nos grupos",
		// 	type:"group",
		// 	permission: false
		// },
		{
			fieldName: "forwardProccess",
			label: "Encaminhar",
			description: "Encaminhar processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "receiveProccess",
			label: "Receber",
			description: "Receber processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "returnProccess",
			label: "Retornar",
			description: "Retornar processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "solveProccess",
			label: "Resolver",
			description: "Retornar processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "archiveProccess",
			label: "Arquivar",
			description: "Retornar processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "commentProccess",
			label: "Adicionar",
			description: "Comentar processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "commentPostFiles",
			label: "Adicionar anexos",
			description: "Adicionar anexos a processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "commentGetFiles",
			label: "Visualizar anexos",
			description: "Ver anexos dos processos",
			type:"process",
			permission: false
		},
		{
			fieldName: "commentDeleteFiles",
			label: "Deletar anexos",
			description: "Remover anexos dos processos",
			type:"process",
			permission: false
		},
		// {
		// 	fieldName: "listGroups",
		// 	label: "Listar",
		// 	description: "Permite listar todos grupos",
		// 	type:"group",
		// 	permission: false
		// },
		// {
		// 	fieldName: "deleteGroup",
		// 	label: "Deletar",
		// 	description: "Permite deletar grupo",
		// 	type:"group",
		// 	permission: false
		// },
		// {
		// 	fieldName: "createGroup",
		// 	label: "Criar",
		// 	description: "Permite criar novos grupos",
		// 	type:"group",
		// 	permission: false
		// },
		// {
		// 	fieldName: "viewMyGroups",
		// 	label: "Listar seus grupos",
		// 	description: "Permite visualizar seus grupos",
		// 	type:"group",
		// 	permission: false
		// },
		{
			fieldName: "viewGroupPermission",
			label: "Listar permissões",
			description: "Permite visualizar permissões no grupo",
			type:"group",
			permission: false
		},
		{
			fieldName: "changeUserPermissionGroup",
			label: "Alterar permisssões",
			description: "Permite alterar as permissões do usuário no grupo",
			type: "user",
		},
		{
			fieldName: "inviteUserGroup",
			label: "Convidar",
			description: "Permite convidar novos usuários para o grupo",
			type: "user",
		},
		{
			fieldName: "viewUsersGroup",
			label: "Visualizar",
			description: "Permite visualizar pessoas no grupo",
			type: "user",
		},
		{
			fieldName: "viewUsersGroupPermission",
			label: "Visualizar permissão",
			description: "Permite visualizar permissções de pessoas no grupo",
			type: "user",
		}
	]

}
